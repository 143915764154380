export const REACT_APP_API = 'https://api.smartserver.vn/';
export const DEFAULT_ERROR_MESSAGE = 'Đã có lỗi xảy ra! Vui lòng thử lại hoặc liên hệ quản trị viên';

export const ACCOUNT_TABS = [
  {
    label: 'Thông tin tài khoản',
  },
  {
    label: 'Đổi mật khẩu',
  },
];

export const HISTORY_TABS = [
  {
    label: 'Lịch sử giao dịch',
  },
  {
    label: 'Lịch sử đổi IP',
  },
];

export const ACCOUNT_TABS_LABEL = {
  UserInfo: 'Thông tin tài khoản',
  ChangePassword: 'Đổi mật khẩu',
};

export const HISTORY_TABS_LABEL = {
  TransactionHistory: 'Lịch sử giao dịch',
  ChangeIPHistory: 'Lịch sử đổi IP',
};

export const PRODUCT_TYPE = {
  VPS: 'VPS',
  Proxy: 'Proxy',
};

export const DEFAULT_LIMIT_ITEM_PER_PAGE = 50;
export const DEFAULT_CURRENT_PAGE = 1;
export const REBOOT_ACTION = 'reboot';
export const PAUSE_ACTION = 'pause';
export const AUTO_FIX_ACTION = 'auto-fix';
export const PREFIX_DOMAIN = 'manage.';
