import { PRODUCT_TYPE } from 'constant/common';
import URL from 'constant/url';
import { Navigate } from 'react-router-dom';
import { instanceAxios } from 'util/request';

export default function PublicRoute({ children }: { children: JSX.Element }) {
  const accessToken = localStorage.getItem('access_token');

  if (accessToken) {
    instanceAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return !accessToken ? children : <Navigate to={`${URL.Management}?type=${PRODUCT_TYPE.VPS}&by_time=using`} />;
}
