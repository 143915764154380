/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'constant/common';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface PayloadChangePassword {
  email: string;
  password: string;
  new_password: string;
}

interface changePassword {
  statusLoadChangePassword: Status;
  paramsChangePassword: PayloadChangePassword;
}

const initialState: changePassword = {
  statusLoadChangePassword: 'idle',
  paramsChangePassword: {
    email: '',
    password: '',
    new_password: '',
  },
};

export const actionChangePassword = createAsyncThunk(
  'account/actionChangePassword',
  async (params: PayloadChangePassword, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'api/user/change-password',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionChangePassword.pending, (state) => {
      state.statusLoadChangePassword = 'loading';
    });
    builder.addCase(actionChangePassword.fulfilled, (state) => {
      state.statusLoadChangePassword = 'success';
      notification.success({ message: 'Đổi mật khẩu thành công!' });
    });
    builder.addCase(actionChangePassword.rejected, (state, action) => {
      state.statusLoadChangePassword = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') ?? DEFAULT_ERROR_MESSAGE });
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingChangePassword = (state: RootState) => state.changePassword.statusLoadChangePassword;

export default slice.reducer;
