/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userProfile from '../components/Account/AccountProfileTab/slice';
import user from '../components/Header/slice';
import verifyUser from '../pages/Verification/slice';
import transferServer from '../pages/Transfer/slice';
import notification from '../components/Header/Notification/slice';
import transactionHistory from '../components/History/Transaction/slice';
import changeIPHistory from '../components/History/ChangeIP/slice';
import auth from '../components/Authentication/Login/slice';
import blacklist from '../components/Management/Blacklist/slice';
import renewByIp from '../components/Management/RenewByIP/slice';
import register from '../components/Authentication/Register/slice';
import changePassword from '../components/Account/slice';
import listServerTable from '../components/Management/Server/slice';
import buyServer from '../components/Buying/BuyServer/slice';
import buyVPSGPU from '../components/Buying/BuyVPSGPU/slice';
import buyProxy from '../components/Buying/BuyProxy/slice';
import paymentProcess from '../components/Buying/Payment/slice';
import upgrade from '../components/Management/ControlOption/Upgrade/slice';

const reducers = {
  userProfile,
  user,
  verifyUser,
  transferServer,
  notification,
  transactionHistory,
  upgrade,
  changeIPHistory,
  auth,
  blacklist,
  renewByIp,
  register,
  changePassword,
  listServerTable,
  buyServer,
  buyVPSGPU,
  buyProxy,
  paymentProcess,
};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
