/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface IUserInfo {
  id: number;
  username: string;
  email: string;
  get_full_name: string;
}

interface getUserProfile {
  statusLoadUserInfo: Status;
  userInfo: IUserInfo;
}

const initialState: getUserProfile = {
  statusLoadUserInfo: 'idle',
  userInfo: {
    id: 0,
    username: '',
    email: '',
    get_full_name: '',
  },
};

export const actionGetUserInfo = createAsyncThunk('userInfo/actionGetUserInfo', async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/api/user',
      method: 'GET',
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const slice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetUserInfo.pending, (state) => {
      state.statusLoadUserInfo = 'loading';
    });
    builder.addCase(actionGetUserInfo.fulfilled, (state, action) => {
      state.statusLoadUserInfo = 'success';
      state.userInfo = get(action, 'payload.data');
    });
    builder.addCase(actionGetUserInfo.rejected, (state) => {
      state.statusLoadUserInfo = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingUserProfile = (state: RootState) => state.user.statusLoadUserInfo;
export const selectUserInfo = (state: RootState) => state.user.userInfo;

export default slice.reducer;
