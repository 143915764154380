/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface PayloadCalculateProductPrice {
  plan_id?: number | null | string;
  quantity: number;
  duration: number;
  nation?: string;
  coupon?: string;
  is_proxy?: boolean;
}

interface ICalculatePayment {
  coupon: string;
  discount: string;
  must_pay: string;
  original_price: string;
  warning: string;
}

interface IPayment {
  statusLoadPaymentProcess: Status;
  calculatePayment: ICalculatePayment;
  calculateProductPrice: PayloadCalculateProductPrice;
}

const initialState: IPayment = {
  statusLoadPaymentProcess: 'idle',
  calculatePayment: {
    coupon: '',
    discount: '',
    must_pay: '',
    original_price: '',
    warning: '',
  },
  calculateProductPrice: {
    plan_id: 0,
    quantity: 1,
    duration: 1,
    is_proxy: true,
  },
};

export const actionCalculateProductPrice = createAsyncThunk(
  'buyServer/actionCreateServer',
  async (params: PayloadCalculateProductPrice, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/create/calculate',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'paymentProcess',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionCalculateProductPrice.pending, (state) => {
      state.statusLoadPaymentProcess = 'loading';
    });
    builder.addCase(actionCalculateProductPrice.fulfilled, (state, action) => {
      state.statusLoadPaymentProcess = 'success';
      state.calculatePayment = get(action, 'payload.data');
    });
    builder.addCase(actionCalculateProductPrice.rejected, (state) => {
      state.statusLoadPaymentProcess = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingPaymentProcess = (state: RootState) => state.paymentProcess.statusLoadPaymentProcess;
export const selectCalculatePayment = (state: RootState) => state.paymentProcess.calculatePayment;

export default slice.reducer;
