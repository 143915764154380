import URL from 'constant/url';
import { Navigate } from 'react-router-dom';
import { instanceAxios } from 'util/request';

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    instanceAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return accessToken ? children : <Navigate to={URL.Login} />;
}
