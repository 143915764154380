import ReactDOM from 'react-dom/client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import Routers from 'router/Routers';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { notification } from 'antd';
import { HelmetProvider } from 'react-helmet-async';

notification.config({ placement: 'top', maxCount: 1, duration: 2 });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <Routers />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
