/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface ITransactionInfo {
  created: string;
  trans_type: string;
  amount: string;
  ip: string;
  description: string;
  update_balance: string;
}

interface ITransaction {
  statusLoadListTransaction: Status;
  transactionInfo: ITransactionInfo[];
}

const initialState: ITransaction = {
  statusLoadListTransaction: 'idle',
  transactionInfo: [
    {
      created: '',
      trans_type: '',
      amount: '',
      ip: '',
      description: '',
      update_balance: '',
    },
  ],
};

export const actionGetListTransaction = createAsyncThunk(
  'transactionHistory/actionGetListTransaction',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/logs/transaction',
        method: 'GET',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetListTransaction.pending, (state) => {
      state.statusLoadListTransaction = 'loading';
    });
    builder.addCase(actionGetListTransaction.fulfilled, (state, action) => {
      state.statusLoadListTransaction = 'success';
      state.transactionInfo = get(action, 'payload.data');
    });
    builder.addCase(actionGetListTransaction.rejected, (state) => {
      state.statusLoadListTransaction = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingTransaction = (state: RootState) => state.transactionHistory.statusLoadListTransaction;
export const selectListTransactionInfo = (state: RootState) => state.transactionHistory.transactionInfo;

export default slice.reducer;
