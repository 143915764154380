/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface IInfo {
  description: string;
  content: string;
  hide: boolean;
}

interface GetAnnouncements {
  statusLoadNotification: Status;
  notificationInfo: IInfo[];
}

const initialState: GetAnnouncements = {
  statusLoadNotification: 'idle',
  notificationInfo: [
    {
      description: '',
      content: '',
      hide: false,
    },
  ],
};

export const actionGetAnnouncements = createAsyncThunk(
  'notification/actionGetAnnouncements',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/announcements',
        method: 'GET',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetAnnouncements.pending, (state) => {
      state.statusLoadNotification = 'loading';
    });
    builder.addCase(actionGetAnnouncements.fulfilled, (state, action) => {
      state.statusLoadNotification = 'success';
      state.notificationInfo = get(action, 'payload.data');
    });
    builder.addCase(actionGetAnnouncements.rejected, (state) => {
      state.statusLoadNotification = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingNotification = (state: RootState) => state.notification.statusLoadNotification;
export const selectLoadingNotificationInfo = (state: RootState) => state.notification.notificationInfo;

export default slice.reducer;
