import { Avatar, Dropdown, MenuProps, Popconfirm, Space } from 'antd';
import { HistoryOutlined, InfoCircleOutlined, KeyOutlined, LogoutOutlined, SwapOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'redux/store';
import { actionLogout } from 'components/Authentication/Login/slice';
import { useNavigate } from 'react-router-dom';
import URL from 'constant/url';
import { useNavigateSearch } from 'hooks/useNavigateSearch';

export default function UserSetting() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const userName = localStorage.getItem('username');

  const handleLogout = () => {
    dispatch(actionLogout());
    localStorage.clear();
    navigate(URL.Login);
  };

  const items: MenuProps['items'] = [
    {
      icon: <InfoCircleOutlined />,
      label: 'Thông tin tài khoản',
      onClick: () => {
        navigateSearch(URL.Account, { type: 'info' });
      },
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      icon: <HistoryOutlined />,
      label: 'Lịch sử giao dịch',
      onClick: () => {
        navigateSearch(URL.History, { type: 'transaction' });
      },
      key: '3',
    },
    {
      type: 'divider',
    },
    {
      icon: <SwapOutlined />,
      label: 'Lịch sử đổi IP',
      onClick: () => {
        navigateSearch(URL.History, { type: 'change-ip' });
      },
      key: '4',
    },
    {
      type: 'divider',
    },
    {
      icon: <KeyOutlined />,
      label: 'Đổi mật khẩu',
      onClick: () => {
        navigateSearch(URL.Account, { type: 'change-password' });
      },
      key: '5',
    },
    {
      type: 'divider',
      className: 'hidden mobile:block',
    },
    {
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
      onClick: handleLogout,
      key: '6',
      className: 'hidden mobile:block',
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} placement="bottom" arrow>
        <a>
          <Space className="mobile:hidden">
            <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{userName?.charAt(0)}</Avatar>
            <div>
              <div className="font-bold text-[#f56a00] leading-5">{userName}</div>
              {/* {userProfile.is_staff === false && userProfile.is_verified === false && (
                <div className="text-[#f56a00] font-semibold leading-5">Chưa xác thực</div>
              )} */}
            </div>
          </Space>
        </a>
      </Dropdown>
      <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight" arrow>
        <a>
          <Avatar className="hidden mobile:block" style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>
            {userName?.charAt(0)}
          </Avatar>
        </a>
      </Dropdown>
      <Popconfirm
        title="Xác nhận"
        description="Bạn có chắc chắn muốn đăng xuất?"
        onConfirm={handleLogout}
        okText="Đồng ý"
        cancelText="Không"
      >
        <LogoutOutlined className="ml-8 align-middle mt-[-4px] cursor-pointer mobile:hidden" />
        <span className="ml-2 mobile:hidden cursor-pointer">Đăng xuất</span>
      </Popconfirm>
    </>
  );
}
