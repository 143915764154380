import { nanoid } from '@reduxjs/toolkit';
import { List, Result, Skeleton } from 'antd';
import {
  actionGetAnnouncements,
  selectLoadingNotification,
  selectLoadingNotificationInfo,
} from 'components/Header/Notification/slice';
import { useEffect } from 'react';
import HTMLString from 'react-html-string';
import { useAppDispatch, useAppSelector } from 'redux/store';

export default function NotificationList() {
  const dispatch = useAppDispatch();
  const listNotification = useAppSelector(selectLoadingNotificationInfo);
  const statusLoadNotification = useAppSelector(selectLoadingNotification);

  useEffect(() => {
    dispatch(actionGetAnnouncements());
  }, [dispatch]);

  return (
    <div className="px-6 mb-12">
      {listNotification.length > 0 ? (
        <>
          <div className="mb-2 mt-2 font-semibold text-xl text-[#007bfc]">Danh sách thông báo</div>
          <List
            pagination={{ pageSize: 6, align: 'center' }}
            dataSource={listNotification}
            renderItem={(item) => (
              <Skeleton loading={statusLoadNotification === 'loading'} paragraph={{ className: 'mb-6' }}>
                <List.Item key={nanoid()}>
                  <List.Item.Meta
                    avatar={null}
                    title={<span className="text-[#007bfc]">{item.description}</span>}
                    description={<HTMLString html={item.content} />}
                  />
                </List.Item>
              </Skeleton>
            )}
          />
        </>
      ) : (
        <Result className="w-full" status="500" subTitle="Không có thông báo" />
      )}
    </div>
  );
}
