/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IdcardOutlined,
  HistoryOutlined,
  AppstoreOutlined,
  CloudServerOutlined,
  ClusterOutlined,
  ExclamationCircleOutlined,
  PlusSquareOutlined,
  HddOutlined,
  NotificationOutlined,
  BookOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import URL from 'constant/url';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { actionGetListRegion, selectListRegion } from 'components/Buying/BuyServer/slice';
import { useEffect } from 'react';
import { actionGetUserProfile } from 'components/Account/AccountProfileTab/slice';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label?: React.ReactNode,
  key?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

interface SidebarItem {
  handleSelectItem: any;
}

export default function Sidebar(props: SidebarItem) {
  const { handleSelectItem } = props;
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const listRegion = useAppSelector(selectListRegion);
  const [searchParams] = useSearchParams();

  const sidebarItems: MenuProps['items'] = [
    getItem('Mua VPS', `${URL.Buying}?type=VPS`, <CloudServerOutlined />, [
      getItem(
        'Theo khu vực',
        'g1',
        null,
        [
          ...listRegion
            .map((value) =>
              getItem(
                value.name,
                `${URL.Buying}?type=VPS&nation=${value.symbol}`,
                <img src={`data:image/png;base64,${value.flag}`} alt="vps" className="w-[24px]" />
              )
            )
            .filter((item) => item?.key !== `${URL.Buying}?type=VPS&nation=EU`),
        ],
        'group'
      ),
      getItem(
        'Theo mục đích',
        'g2',
        null,
        [
          getItem('PTU', `${URL.Buying}?type=VPS&nation=EU`, <HddOutlined />),
          getItem('GPU', URL.BuyingVPSGPU, <HddOutlined />),
        ],
        'group'
      ),
    ]),
    getItem('Quản lý VPS', `${URL.Management}?type=VPS`, <AppstoreOutlined />, [
      getItem('Đang sử dụng', `${URL.Management}?type=VPS&by_time=using`, null),
      getItem('Còn hạn (dưới 3 ngày)', `${URL.Management}?type=VPS&by_time=due`, null),
      getItem('Đã hết hạn', `${URL.Management}?type=VPS&by_time=expired`, null),
      getItem('Tất cả dịch vụ', `${URL.Management}?type=VPS&by_time=all`, null),
    ]),
    getItem('Mua Proxy', URL.BuyingProxy, <ClusterOutlined />),
    getItem('Quản lý Proxy', `${URL.Management}?type=Proxy`, <AppstoreOutlined />, [
      getItem('Đang sử dụng', `${URL.Management}?type=Proxy&by_time=using`, null),
      getItem('Còn hạn (dưới 3 ngày)', `${URL.Management}?type=Proxy&by_time=due`, null),
      getItem('Đã hết hạn', `${URL.Management}?type=Proxy&by_time=expired`, null),
      getItem('Tất cả dịch vụ', `${URL.Management}?type=Proxy&by_time=all`, null),
    ]),
    getItem('Danh sách IP đã mua', URL.Blacklist, <ExclamationCircleOutlined />),
    getItem('Gia hạn bằng IP', URL.RenewByIP, <PlusSquareOutlined />),
    getItem('Lịch sử', URL.History, <HistoryOutlined />),
    getItem('Tài khoản', URL.Account, <IdcardOutlined />),
    getItem('Thông báo', URL.Notification, <NotificationOutlined />),
    getItem(
      <a
        href={`https://${window.location.hostname.replace('manage.', '')}/guide`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Hướng dẫn sử dụng
      </a>,
      '',
      <BookOutlined />
    ),
  ];

  useEffect(() => {
    dispatch(actionGetUserProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionGetListRegion());
  }, [dispatch]);

  return (
    <Menu
      defaultOpenKeys={[`${window.location.pathname}?type=${searchParams.get('type')}`]}
      className="h-full border-r-0"
      mode="inline"
      theme="dark"
      selectedKeys={[pathname]}
      items={sidebarItems}
      onSelect={handleSelectItem}
    />
  );
}
