/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'constant/common';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface PayloadRegister {
  username: string;
  password: string;
  grant_type: string;
  email: string;
  client_id: string;
  phone: string;
  partner: string;
}

export interface IUserRegister {
  id: number;
  username: string;
  email: string;
}

interface registerUser {
  statusLoadRegister: Status;
  user: IUserRegister;
  paramsRegister: PayloadRegister;
}

const initialState: registerUser = {
  statusLoadRegister: 'idle',
  user: {
    id: 0,
    username: '',
    email: '',
  },
  paramsRegister: {
    username: '',
    password: '',
    grant_type: '',
    email: '',
    client_id: '',
    phone: '',
    partner: '',
  },
};

export const actionRegister = createAsyncThunk(
  'auth/actionRegister',
  async (params: PayloadRegister, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'api/user/register',
        method: 'POST',
        data: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionRegister.pending, (state) => {
      state.statusLoadRegister = 'loading';
    });
    builder.addCase(actionRegister.fulfilled, (state, action) => {
      state.statusLoadRegister = 'success';
      state.user = get(action, 'payload.data.user');
    });
    builder.addCase(actionRegister.rejected, (state, action) => {
      state.statusLoadRegister = 'error';
      notification.error({
        message: get(action, 'payload.response.data.reason') ?? DEFAULT_ERROR_MESSAGE,
      });
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingRegister = (state: RootState) => state.register.statusLoadRegister;
export const selectRegisterInfo = (state: RootState) => state.register.user;

export default slice.reducer;
