/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface IBlacklistIP {
  id: number;
  created: string;
  ip: string;
  client: number;
}

export interface IBlacklist {
  count: number;
  next: string;
  previous: string;
  results: IBlacklistIP[];
}

interface PayloadIPID {
  ipid: number;
}

interface IGetListBlacklistTable {
  page?: number;
  page_size?: number;
}

interface getListBlacklistTable {
  statusLoadBlacklist: Status;
  statusLoadClearBlacklist: Status;
  statusLoadRemoveIPFromBlacklist: Status;
  listBlacklistTable: IBlacklist;
}

const initialState: getListBlacklistTable = {
  statusLoadBlacklist: 'idle',
  statusLoadClearBlacklist: 'idle',
  statusLoadRemoveIPFromBlacklist: 'idle',
  listBlacklistTable: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
};

export const actionGetBlacklist = createAsyncThunk(
  'listServerTable/actionGetBlacklist',
  async (params: IGetListBlacklistTable, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/user/blacklist',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionClearBlacklist = createAsyncThunk(
  'listServerTable/actionClearBlacklist',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/user/blacklist',
        method: 'DELETE',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionPostRemoveIPFromBlacklist = createAsyncThunk(
  'listServerTable/actionPostRemoveIPFromBlacklist',
  async (params: PayloadIPID, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/user/blacklist/remove',
        method: 'DELETE',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'listBlacklistTable',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionGetBlacklist.pending, (state) => {
      state.statusLoadBlacklist = 'loading';
    });
    builder.addCase(actionGetBlacklist.fulfilled, (state, action) => {
      state.statusLoadBlacklist = 'success';
      state.listBlacklistTable = get(action, 'payload.data');
    });
    builder.addCase(actionGetBlacklist.rejected, (state) => {
      state.statusLoadBlacklist = 'error';
    });

    builder.addCase(actionClearBlacklist.pending, (state) => {
      state.statusLoadClearBlacklist = 'loading';
    });
    builder.addCase(actionClearBlacklist.fulfilled, (state) => {
      state.statusLoadClearBlacklist = 'success';
    });
    builder.addCase(actionClearBlacklist.rejected, (state) => {
      state.statusLoadClearBlacklist = 'error';
    });

    builder.addCase(actionPostRemoveIPFromBlacklist.pending, (state) => {
      state.statusLoadRemoveIPFromBlacklist = 'loading';
    });
    builder.addCase(actionPostRemoveIPFromBlacklist.fulfilled, (state) => {
      state.statusLoadRemoveIPFromBlacklist = 'success';
      notification.success({ message: 'Đã xóa IP khỏi danh sách' });
    });
    builder.addCase(actionPostRemoveIPFromBlacklist.rejected, (state) => {
      state.statusLoadRemoveIPFromBlacklist = 'error';
    });
  },
});

export const selectLoadingBlacklist = (state: RootState) => state.blacklist.statusLoadBlacklist;
export const selectLoadingClearBlacklist = (state: RootState) => state.blacklist.statusLoadClearBlacklist;
export const selectLoadingRemoveIPFromBlacklist = (state: RootState) => state.blacklist.statusLoadRemoveIPFromBlacklist;
export const selectBlacklist = (state: RootState) => state.blacklist.listBlacklistTable;

export default slice.reducer;
