/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'constant/common';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface IAuth {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
  user: {
    username: string;
    email: string;
    client: string;
    banking_message: string;
    bank_name: string;
    bank_acc_name: string;
    bank_acc_no: string;
    is_verified: boolean;
  };
}

export interface PayloadLogin {
  email: string;
  password: string;
  grant_type: string;
  client_id: string;
}

export interface PayloadRefreshToken {
  grant_type: string;
  client_id: string;
}

interface getUser {
  statusLoadLogin: Status;
  isLogin: boolean;
  userLogin: IAuth;
  paramsLogin: PayloadLogin;
}

const initialState: getUser = {
  statusLoadLogin: 'idle',
  isLogin: false,
  paramsLogin: {
    email: '',
    password: '',
    grant_type: '',
    client_id: '',
  },
  userLogin: {
    access_token: '',
    expires_in: 0,
    scope: '',
    token_type: '',
    user: {
      username: '',
      email: '',
      client: '',
      banking_message: '',
      bank_name: '',
      bank_acc_name: '',
      bank_acc_no: '',
      is_verified: false,
    },
  },
};

export const actionLogin = createAsyncThunk('auth/actionLogin', async (params: PayloadLogin, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/api/token',
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
    actionLogout(state) {
      state.isLogin = false;
      state.userLogin = initialState.userLogin;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionLogin.pending, (state) => {
      state.statusLoadLogin = 'loading';
    });
    builder.addCase(actionLogin.fulfilled, (state, action) => {
      state.statusLoadLogin = 'success';
      state.isLogin = true;
      state.userLogin = get(action, 'payload.data');
      localStorage.setItem('access_token', state.userLogin.access_token);
      if (state.userLogin.user.is_verified === true) {
        localStorage.setItem('is_verified', 'true');
      }
      localStorage.setItem('username', state.userLogin.user.username);
      localStorage.setItem('email', state.userLogin.user.email);
      localStorage.setItem('banking_message', state.userLogin.user.banking_message);
      localStorage.setItem('bank_name', state.userLogin.user.bank_name);
      localStorage.setItem('bank_acc_name', state.userLogin.user.bank_acc_name);
      localStorage.setItem('bank_acc_no', state.userLogin.user.bank_acc_no);
    });
    builder.addCase(actionLogin.rejected, (state, action) => {
      state.statusLoadLogin = 'error';
      notification.error({
        message: get(action, 'payload.response.data.reason') ?? DEFAULT_ERROR_MESSAGE,
      });
    });
  },
});

export const { actionResetStore, actionLogout } = slice.actions;

export const selectLoadingLogin = (state: RootState) => state.auth.statusLoadLogin;
export const selectIsLogin = (state: RootState) => state.auth.isLogin;
export const selectAuth = (state: RootState) => state.auth.userLogin;

export default slice.reducer;
