import { Button, Result, Spin, notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import URL from 'constant/url';
import { PRODUCT_TYPE } from 'constant/common';
import { useNavigateSearch } from 'hooks/useNavigateSearch';
import { Helmet } from 'react-helmet-async';
import { actionSendVerifyToken, actionVerifyUser, selectLoadingVerifyToken } from './slice';

export default function VerifyUser() {
  const dispatch = useAppDispatch();
  const navigateSearch = useNavigateSearch();
  const loadingVerifyToken = useAppSelector(selectLoadingVerifyToken);
  const [disabled, setDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle('Xác thực tài khoản');
  }, []);

  function verifyUserInfo() {
    dispatch(actionVerifyUser());
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 60000);
  }

  function sendVerifyToken() {
    const token = searchParams.get('token') as string;
    const decodedParam = token.replaceAll(' ', '+');
    dispatch(actionSendVerifyToken({ token: decodedParam }));
    return null;
  }

  useEffect(() => {
    if (loadingVerifyToken === 'success') {
      notification.success({ message: 'Xác thực tài khoản thành công! Vui lòng đăng nhập lại và tiếp tục sử dụng' });
      navigateSearch(URL.Management, { type: PRODUCT_TYPE.VPS, by_time: 'using' });
    }

    const interval = setInterval(() => {
      if (searchParams.get('token')) {
        sendVerifyToken();
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingVerifyToken]);

  useEffect(() => {
    if (localStorage.getItem('is_verified')) {
      navigateSearch(URL.Management, { type: PRODUCT_TYPE.VPS, by_time: 'using' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToLogin = () => {
    localStorage.clear();
    navigateSearch(URL.Login, {});
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Result
        status="403"
        icon={null}
        title={
          <div>
            {searchParams.get('token') ? (
              <>
                <div>
                  <Spin size="large" />
                </div>
                <span className="text-base">
                  Tài khoản của quý khách đang được xác thực. <br /> Trong trường hợp trình duyệt không tự chuyển trang,
                  vui lòng đăng nhập vào hệ thống và kiểm tra tình trạng của tài khoản.
                </span>
              </>
            ) : (
              <span className="text-base">Tài khoản chưa được xác thực.</span>
            )}
          </div>
        }
        subTitle={
          <div className="text-red-500">
            {searchParams.get('token') ? (
              ''
            ) : (
              <>
                <span>Vui lòng nhấn Xác thực và kiểm tra email.</span>
                <br />
                <span>
                  Trong trường hợp trình duyệt không tự chuyển trang, vui lòng đăng nhập vào hệ thống và kiểm tra tình
                  trạng của tài khoản
                </span>
              </>
            )}
          </div>
        }
        extra={
          <>
            <Button hidden={!!searchParams.get('token')} disabled={disabled} type="primary" onClick={verifyUserInfo}>
              Xác thực
            </Button>
            <Button type="primary" onClick={backToLogin}>
              Quay lại trang đăng nhập
            </Button>
          </>
        }
      />
    </>
  );
}
