/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Breadcrumb, Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { NotificationOutlined } from '@ant-design/icons';
import UserSetting from 'components/Header/UserSetting';
import Recharge from 'components/Header/Recharge';
import {
  actionGetUserProfile,
  selectLoadingUserProfile,
  selectUserProfile,
} from 'components/Account/AccountProfileTab/slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { PREFIX_DOMAIN } from 'constant/common';
import AccountBalance from 'components/Header/AccountBalance';
import MobileMenu from 'components/Header/MobileMenu';

interface IHeader {
  isEnableNotification?: boolean;
  handleEnableNotification?: () => void;
  breadcrumb?: string;
}

export default function HeaderLayout(props: IHeader) {
  const { breadcrumb, isEnableNotification, handleEnableNotification } = props;
  const dispatch = useAppDispatch();
  const domain = window.location.hostname;
  const userProfile = useAppSelector(selectUserProfile);
  const statusLoadUserProfile = useAppSelector(selectLoadingUserProfile);
  const [isLoadingAmountButton, setLoadingAmountButton] = useState(false);

  const checkAmountMoney = () => {
    dispatch(actionGetUserProfile());
    setLoadingAmountButton(true);
  };

  useEffect(() => {
    if (statusLoadUserProfile === 'success') {
      setTimeout(() => {
        setLoadingAmountButton(false);
      }, 1000);
    }
  }, [statusLoadUserProfile]);

  return (
    <div className="flex justify-between flex-wrap mobile:mt-2 w-full">
      <div
        className={`flex w-[40%] mobile:w-full mobile:justify-center ${
          userProfile.is_notification === true ? 'visible' : 'invisible'
        }`}
      >
        <a
          onClick={() => {
            window.open(`https://${domain.replace(PREFIX_DOMAIN, '')}`, '_self');
          }}
        >
          <img
            className="px-8 py-2 w-full"
            alt="example"
            src={`https://buffer.httv.workers.dev/assets/${domain}/logo/logo-w.png`}
          />
        </a>
        <Breadcrumb
          className="flex items-center mobile:hidden"
          items={[
            {
              title: 'Trang chủ',
            },
            {
              title: breadcrumb,
            },
          ]}
        />
      </div>
      <div className="flex mobile:w-full">
        <Space size={[8, 8]} className="flex-wrap mobile:justify-center mobile:w-full">
          <MobileMenu />
          <Button
            type="primary"
            size="large"
            className="text-sm self-center mobile:hidden"
            icon={<NotificationOutlined />}
            onClick={handleEnableNotification}
          >
            {isEnableNotification ? 'Tắt thông báo' : 'Bật thông báo'}
          </Button>
          <Recharge />
          <AccountBalance checkAmountMoney={checkAmountMoney} isLoadingAmountButton={isLoadingAmountButton} />
          <UserSetting />
        </Space>
      </div>
    </div>
  );
}
