import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import URL from 'constant/url';
import { ACCOUNT_TABS_LABEL, HISTORY_TABS_LABEL, PRODUCT_TYPE } from 'constant/common';
import { Helmet } from 'react-helmet-async';
import Notification from 'components/Header/Notification/index';
import HeaderLayout from './HeaderLayout';
import Sidebar from './Sidebar';

interface MainLayoutProps {
  children: JSX.Element;
}

export default function MainLayout(props: MainLayoutProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { children } = props;
  const [breadcrumb, setBreadcrumb] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  // const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [isEnableNotification, setEnableNotification] = useState(false);

  function handleEnableNotification() {
    if (!isEnableNotification) {
      setEnableNotification(true);
    } else {
      setEnableNotification(false);
    }
  }

  // set breadcrumb header follow to url param
  function handleSelectItem(data: { key: string }) {
    switch (data.key) {
      case URL.Account:
        setBreadcrumb(ACCOUNT_TABS_LABEL.UserInfo);
        break;
      case URL.History:
        setBreadcrumb(HISTORY_TABS_LABEL.TransactionHistory);
        break;
      case URL.BuyingVPSGPU:
        setBreadcrumb('Mua VPS');
        break;
      case URL.BuyingProxy:
        setBreadcrumb('Mua Proxy');
        break;
      case `${URL.Management}?type=VPS&by_time=using`:
        setBreadcrumb('Danh sách VPS - Đang sử dụng');
        break;
      case `${URL.Management}?type=VPS&by_time=due`:
        setBreadcrumb('Danh sách VPS - Còn hạn (dưới 3 ngày)');
        break;
      case `${URL.Management}?type=VPS&by_time=expired`:
        setBreadcrumb('Danh sách VPS - Đã hết hạn');
        break;
      case `${URL.Management}?type=VPS&by_time=all`:
        setBreadcrumb('Danh sách VPS - Tất cả dịch vụ');
        break;
      case `${URL.Management}?type=Proxy&by_time=using`:
        setBreadcrumb('Danh sách Proxy - Đang sử dụng');
        break;
      case `${URL.Management}?type=Proxy&by_time=due`:
        setBreadcrumb('Danh sách Proxy - Còn hạn (dưới 3 ngày)');
        break;
      case `${URL.Management}?type=Proxy&by_time=expired`:
        setBreadcrumb('Danh sách Proxy - Đã hết hạn');
        break;
      case `${URL.Management}?type=Proxy&by_time=all`:
        setBreadcrumb('Danh sách Proxy - Tất cả dịch vụ');
        break;
      case URL.Blacklist:
        setBreadcrumb('Danh sách IP đã mua');
        break;
      case URL.RenewByIP:
        setBreadcrumb('Gia hạn bằng IP');
        break;
      case URL.Notification:
        setBreadcrumb('Thông báo');
        break;
      default:
        break;
    }
    navigate(data.key);
  }

  // get breadcrum after reload page
  useEffect(() => {
    if (searchParams.get('type') === 'info' || window.location.pathname === URL.Account) {
      setBreadcrumb(ACCOUNT_TABS_LABEL.UserInfo);
    }
    if (searchParams.get('type') === 'change-password') {
      setBreadcrumb(ACCOUNT_TABS_LABEL.ChangePassword);
    }
    if (searchParams.get('type') === 'transaction' || window.location.pathname === URL.History) {
      setBreadcrumb(HISTORY_TABS_LABEL.TransactionHistory);
    }
    if (searchParams.get('type') === 'change-ip') {
      setBreadcrumb(HISTORY_TABS_LABEL.ChangeIPHistory);
    }
    if (searchParams.get('type') === PRODUCT_TYPE.VPS && searchParams.has('nation')) {
      setBreadcrumb('Mua VPS');
    }
    if (window.location.pathname === URL.BuyingVPSGPU) {
      setBreadcrumb('Mua VPS');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.VPS && searchParams.get('by_time') === 'using') {
      setBreadcrumb('Danh sách VPS - Đang sử dụng');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.VPS && searchParams.get('by_time') === 'due') {
      setBreadcrumb('Danh sách VPS - Còn hạn (dưới 3 ngày)');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.VPS && searchParams.get('by_time') === 'expired') {
      setBreadcrumb('Danh sách VPS - Đã hết hạn');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.VPS && searchParams.get('by_time') === 'all') {
      setBreadcrumb('Danh sách VPS - Tất cả dịch vụ');
    }
    if (window.location.pathname === URL.BuyingProxy) {
      setBreadcrumb('Mua Proxy');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.Proxy && searchParams.get('by_time') === 'using') {
      setBreadcrumb('Danh sách Proxy - Đang sử dụng');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.Proxy && searchParams.get('by_time') === 'due') {
      setBreadcrumb('Danh sách Proxy - Còn hạn (dưới 3 ngày)');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.Proxy && searchParams.get('by_time') === 'expired') {
      setBreadcrumb('Danh sách Proxy - Đã hết hạn');
    }
    if (searchParams.get('type') === PRODUCT_TYPE.Proxy && searchParams.get('by_time') === 'all') {
      setBreadcrumb('Danh sách Proxy - Tất cả dịch vụ');
    }
    if (window.location.pathname === URL.Blacklist) {
      setBreadcrumb('Danh sách IP đã mua');
    }
    if (window.location.pathname === URL.RenewByIP) {
      setBreadcrumb('Gia hạn bằng IP');
    }
    if (window.location.pathname === URL.Notification) {
      setBreadcrumb('Thông báo');
    }
  }, [searchParams]);

  // const goToVerifyPage = () => {
  //   setOpenVerifyModal(false);
  //   navigate(URL.VerifyUser);
  // };

  // useEffect(() => {
  //   if (!localStorage.getItem('is_verified')) {
  //     setOpenVerifyModal(true);
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <title>{breadcrumb}</title>
      </Helmet>
      {/* <Modal
        open={openVerifyModal}
        title="Thông báo"
        onOk={goToVerifyPage}
        okText="Xác thực"
        cancelButtonProps={{ hidden: true }}
        closeIcon={false}
      >
        <span className="text-red-500">
          Tài khoản của quý khách chưa được xác thực.
          <br /> Vui lòng xác thực để tiếp tục sử dụng.
        </span>
      </Modal> */}
      <Layout className="h-full">
        <Header
          className="h-auto mobile:hidden mobile:px-1 w-full pr-[50px] pl-0"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <HeaderLayout
            breadcrumb={breadcrumb}
            handleEnableNotification={handleEnableNotification}
            isEnableNotification={isEnableNotification}
          />
        </Header>
        <div className="w-full h-full overflow-hidden relative">
          <Layout className="h-full" hasSider>
            <Sider
              className="mobile:hidden"
              width={220}
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
            >
              <Sidebar handleSelectItem={handleSelectItem} />
            </Sider>
            <Layout>
              <div className="hidden mobile:block mb-2">
                <HeaderLayout />
              </div>
              <Content className="overflow-auto h-full relative">
                {isEnableNotification && window.location.pathname !== URL.Notification ? <Notification /> : null}
                {children}
              </Content>
              {/* <Footer style={{ textAlign: 'center' }}>Copyright...</Footer> */}
            </Layout>
          </Layout>
        </div>
      </Layout>
    </>
  );
}
