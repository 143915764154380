import { ReactElement, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import URL from 'constant/url';
import VerifyUser from 'pages/Verification/VerifyUser';
import NotificationList from 'pages/NotificationList';
import MainLayout from '../layouts/MainLayout';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Account = lazy(() => import('pages/Account'));
const History = lazy(() => import('pages/History'));
const Blacklist = lazy(() => import('components/Management/Blacklist/Blacklist'));
const RenewByIP = lazy(() => import('components/Management/RenewByIP/RenewByIP'));
const Management = lazy(() => import('pages/Management'));
const Login = lazy(() => import('pages/Login'));
const Buying = lazy(() => import('pages/Buying'));
const BuyProxy = lazy(() => import('components/Buying/BuyProxy/index'));
const BuyVPSGPU = lazy(() => import('components/Buying/BuyVPSGPU/index'));
const Register = lazy(() => import('pages/Register'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const NotFound = lazy(() => import('pages/NotFound'));
const Transfer = lazy(() => import('pages/Transfer/TransferConfirm'));

interface ItemType {
  key: string;
  components: ReactElement;
  layout: string;
  private: boolean;
}

const routerItems: ItemType[] = [
  {
    key: URL.Management,
    components: <Management />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.Buying,
    components: <Buying />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.BuyingVPSGPU,
    components: <BuyVPSGPU />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.BuyingProxy,
    components: <BuyProxy />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.Account,
    components: <Account />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.Blacklist,
    components: <Blacklist />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.RenewByIP,
    components: <RenewByIP />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.History,
    components: <History />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.Notification,
    components: <NotificationList />,
    layout: 'main',
    private: true,
  },
  {
    key: URL.Login,
    components: <Login />,
    layout: 'none',
    private: false,
  },
  {
    key: URL.Register,
    components: <Register />,
    layout: 'none',
    private: false,
  },
  {
    key: URL.VerifyUser,
    components: <VerifyUser />,
    layout: 'none',
    private: true,
  },
  {
    key: URL.ForgotPassword,
    components: <ForgotPassword />,
    layout: 'none',
    private: false,
  },
  {
    key: URL.NotFound,
    components: <NotFound />,
    layout: 'none',
    private: true,
  },
  {
    key: URL.Transfer,
    components: <Transfer />,
    layout: 'none',
    private: true,
  },
];

export default function Routers() {
  return (
    <Routes>
      {routerItems.map((item) => {
        let element = item.components;
        element = <Suspense fallback={null}>{element}</Suspense>;

        if (item.layout === 'main') {
          element = <MainLayout>{element}</MainLayout>;
        }

        if (item.private) {
          element = <PrivateRoute>{element}</PrivateRoute>;
        } else {
          element = <PublicRoute>{element}</PublicRoute>;
        }

        return <Route key={item.key} path={item.key} element={element} />;
      })}
    </Routes>
  );
}
