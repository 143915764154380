/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';
import { get } from 'lodash';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface ISendVerifyToken {
  token: string | null;
}

export interface ValidateInformation {
  ip: string;
  from: string;
}

interface getTransferTokenStatus {
  statusLoadValidateToken: Status;
  statusLoadConfirmToken: Status;
  validateInformation: ValidateInformation;
}

const initialState: getTransferTokenStatus = {
  statusLoadValidateToken: 'idle',
  statusLoadConfirmToken: 'idle',
  validateInformation: {
    ip: '',
    from: '',
  },
};

export const actionValidateTransfer = createAsyncThunk(
  'transfer/validate',
  async (data: ISendVerifyToken, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/transfer/validate',
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const actionConfirmTransfer = createAsyncThunk(
  'transfer/confirmation',
  async (data: ISendVerifyToken, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/transfer/confirm',
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'transferServer',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionValidateTransfer.pending, (state) => {
      state.statusLoadValidateToken = 'loading';
    });
    builder.addCase(actionValidateTransfer.fulfilled, (state, action) => {
      state.statusLoadValidateToken = 'success';
      state.validateInformation = get(action, 'payload.data.details');
    });
    builder.addCase(actionValidateTransfer.rejected, (state) => {
      state.statusLoadValidateToken = 'error';
    });
    builder.addCase(actionConfirmTransfer.pending, (state) => {
      state.statusLoadConfirmToken = 'loading';
    });
    builder.addCase(actionConfirmTransfer.fulfilled, (state) => {
      state.statusLoadConfirmToken = 'success';
    });
    builder.addCase(actionConfirmTransfer.rejected, (state) => {
      state.statusLoadConfirmToken = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;
export const selectLoadingValidateToken = (state: RootState) => state.transferServer.statusLoadValidateToken;
export const selectLoadingConfirmToken = (state: RootState) => state.transferServer.statusLoadConfirmToken;
export const selectValidateToken = (state: RootState) => state.transferServer.validateInformation;

export default slice.reducer;
