/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface IChangeIPInfo {
  created: string;
  old_ip: string;
  new_ip: string;
  server_name: string;
  id: string;
}

interface IChangeIPHistory {
  statusLoadListChangeIP: Status;
  changeIPInfo: IChangeIPInfo[];
}

const initialState: IChangeIPHistory = {
  statusLoadListChangeIP: 'idle',
  changeIPInfo: [
    {
      created: '',
      old_ip: '',
      new_ip: '',
      server_name: '',
      id: '',
    },
  ],
};

export const actionGetListChangeIPHistory = createAsyncThunk(
  'transaction/actionGetListChangeIPHistory',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/logs/change-ip',
        method: 'GET',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'changeIPHistory',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetListChangeIPHistory.pending, (state) => {
      state.statusLoadListChangeIP = 'loading';
    });
    builder.addCase(actionGetListChangeIPHistory.fulfilled, (state, action) => {
      state.statusLoadListChangeIP = 'success';
      state.changeIPInfo = get(action, 'payload.data').map((item: IChangeIPInfo) => ({
        ...item,
        id: nanoid(),
      }));
    });

    builder.addCase(actionGetListChangeIPHistory.rejected, (state) => {
      state.statusLoadListChangeIP = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingListChangeIP = (state: RootState) => state.changeIPHistory.statusLoadListChangeIP;
export const selectListTransactionInfo = (state: RootState) => state.changeIPHistory.changeIPInfo;

export default slice.reducer;
