/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import { Alert, Button } from 'antd';
import { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { useAppDispatch, useAppSelector } from 'redux/store';
import HTMLString from 'react-html-string';
import { useNavigate } from 'react-router-dom';
import URL from 'constant/url';
import { actionGetAnnouncements, selectLoadingNotificationInfo } from './slice';

export default function Notification() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listNotification = useAppSelector(selectLoadingNotificationInfo);

  const components = {
    br: (props: any) => <a {...props} content="." />,
    p: (props: any) => <a {...props} content="" />,
  };

  useEffect(() => {
    dispatch(actionGetAnnouncements());
  }, [dispatch]);

  return (
    <div className="flex justify-center">
      <Alert
        type="info"
        showIcon
        closable
        className="mobile:w-full mt-2 w-4/5"
        action={
          <Button
            size="middle"
            className="text-sm ml-2"
            hidden={listNotification.length === 0}
            onClick={() => {
              navigate(URL.Notification);
            }}
          >
            Chi tiết
          </Button>
        }
        message={
          <Marquee pauseOnHover gradient={false}>
            <HTMLString
              html={
                listNotification.length > 0
                  ? listNotification[0].content.replaceAll('\r\n\r\n', ' ').replaceAll('.', '. ')
                  : 'Không có thông báo'
              }
              components={components}
            />
          </Marquee>
        }
      />
    </div>
  );
}
