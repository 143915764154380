/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface IListServer {
  country: string;
  he_dieu_hanh: string;
  het_han: string;
  ip: string;
  is_auto_renew: boolean;
  is_expired: number;
  is_lock: boolean;
  is_trial: boolean;
  lock_reason: null;
  ngay_mua: string;
  note: string;
  plan_number: string;
  price_usd: string;
  price_vnd: string;
  server_id: number;
  ten_dich_vu: string;
  trang_thai: string;
  remote_port: string;
  ip_port: string;
  change_ip_time: number;
}

export interface PayloadListServerTable {
  servers: IListServer[];
  total_vps: number;
  total_vps_off: number;
  total_vps_running: number;
}

interface PayloadListIPInfo {
  ip: string;
  change_remind: number;
  has_state: Array<string>;
  range_ip: Array<string>;
  country: string;
  plan_id: number;
  show_isp: boolean;
  isp: Array<string>;
  support_os: Array<any>;
  current_os: number;
  current_remote_port: string;
}

interface PayloadServerControl {
  sid: string;
}

interface PayloadCalculateRenewServer {
  sid: string;
  month: number;
}

interface PayloadSupportOS {
  os: Record<string, number | boolean | string>;
}

interface PayloadReinstallServer {
  install_chrome: string;
  install_firefox: string;
  random_remote_port: string;
  remote_port: string;
  random_password: string;
  random_username?: string;
  username?: string;
  password: string;
  os?: number;
  type?: string;
  sid: string;
}

interface PayloadGetSupportOS {
  country?: string;
}

interface PayloadRefund {
  sid: string;
}

interface IRenewProduct {
  old_expired_day: string;
  new_expired_day: string;
  expense: number;
}

interface IRenew {
  result: {
    error: Record<string, number | boolean | string>;
    success: Record<string, IRenewProduct>;
  };
}

interface ICalculateRenew {
  result: string;
  reason: string;
  details: {
    expense: string;
    warning: string;
  };
}

interface ICalculateRefund {
  result: {
    error: Record<string, number | boolean | string>;
    success: Record<string, number | boolean | string>;
  };
}

interface IServerLoginInfo {
  server_name: string;
  ip: string;
  username: string;
  password: string;
  template: string;
  ram: string;
  cpu: string;
  ssd: string;
  remote_port: string;
  install_chrome: boolean;
  install_firefox: boolean;
  country: string;
  PlanName: string;
  change_ip_time: number;
  TenDichVu: string;
  note: string;
}

interface IChangeIPInfo {
  ip: string;
  isp?: string;
  has_state?: string;
}

interface IFindUser {
  keyword: string;
}

interface ITransfer {
  to_id: number;
  sid: string;
}

interface IFindUserResult {
  result: string;
  data: Record<string, number | boolean | string>;
}

interface ITransferServerResult {
  result: {
    error: Record<string, number | boolean | string>;
    success: Record<string, number | boolean | string>;
  };
}

interface IChangeIP {
  ip: string;
  os_id: number;
  proxy_type?: string;
  remote_port?: string;
  not_remove_data: boolean;
  install_chrome: boolean;
  install_firefox: boolean;
  range_ip: string;
  password?: string;
  random_password: boolean;
  random_remote_port: boolean;
  isp?: string;
  has_state?: string;
}

interface IChangeIPResult {
  new_ip: string;
  username: string;
  password: string;
  remote_port: number;
}

interface IGetListServerTable {
  limit?: number;
  page?: number;
  by_status?: string;
  by_time?: string;
  proxy?: boolean;
  is_staff?: boolean;
  ips?: string;
}

interface PayloadAutoRenew {
  sid: string;
}

interface PayloadActionServerInputNote {
  sid: string;
  note: string;
}

interface PayloadActionShowServerLoginInfo {
  sid: string;
}

interface PayloadActionResetPassword {
  sid: string;
}

interface IReinstallServerResult {
  ip: string;
  username: string;
  password: string;
  remote_port: string;
}

interface getListServerTable {
  statusLoadListServerTable: Status;
  statusLoadRebootServer: Status;
  statusLoadPauseServer: Status;
  statusLoadSupportOS: Status;
  statusLoadAutoFix: Status;
  statusLoadReinstallServer: Status;
  statusLoadRenewServer: Status;
  statusLoadCalculateRenewMultipleServer: Status;
  statusLoadCalculateRenewServer: Status;
  statusLoadCalculateRefund: Status;
  statusLoadRefund: Status;
  statusLoadServerInputNote: Status;
  statusLoadAutoRenew: Status;
  statusLoadResetPassword: Status;
  statusLoadChangeIP: Status;
  statusLoadGetChangeIPInfo: Status;
  statusLoadFindUser: Status;
  statusLoadTransferServer: Status;
  listSupportOS: PayloadSupportOS;
  listServerTable: PayloadListServerTable;
  reinstallServerResult: IReinstallServerResult;
  itemsRenew: IRenew;
  itemsRenewCalculate: ICalculateRenew;
  calculateRefund: ICalculateRefund;
  serverRefund: ICalculateRefund;
  serverLoginInfo: IServerLoginInfo;
  changeIPResult: IChangeIPResult;
  changeIPInfo: PayloadListIPInfo;
  findUser: IFindUserResult;
  transferServerResult: ITransferServerResult;
}

const initialState: getListServerTable = {
  statusLoadListServerTable: 'idle',
  statusLoadRebootServer: 'idle',
  statusLoadPauseServer: 'idle',
  statusLoadAutoFix: 'idle',
  statusLoadSupportOS: 'idle',
  statusLoadReinstallServer: 'idle',
  statusLoadCalculateRenewServer: 'idle',
  statusLoadCalculateRefund: 'idle',
  statusLoadRefund: 'idle',
  statusLoadServerInputNote: 'idle',
  statusLoadAutoRenew: 'idle',
  statusLoadResetPassword: 'idle',
  statusLoadChangeIP: 'idle',
  statusLoadGetChangeIPInfo: 'idle',
  statusLoadFindUser: 'idle',
  statusLoadTransferServer: 'idle',
  itemsRenew: {
    result: {
      error: {},
      success: {},
    },
  },
  itemsRenewCalculate: {
    result: '',
    reason: '',
    details: {
      expense: '',
      warning: '',
    },
  },
  findUser: {
    result: '',
    data: {},
  },
  transferServerResult: {
    result: {
      error: {},
      success: {},
    },
  },
  calculateRefund: {
    result: {
      error: {},
      success: {},
    },
  },
  serverRefund: {
    result: {
      error: {},
      success: {},
    },
  },
  serverLoginInfo: {
    server_name: '',
    ip: '',
    username: '',
    password: '',
    template: '',
    ram: '',
    cpu: '',
    ssd: '',
    remote_port: '',
    install_chrome: false,
    install_firefox: false,
    country: '',
    PlanName: '',
    change_ip_time: 0,
    TenDichVu: '',
    note: '',
  },
  changeIPResult: {
    new_ip: '',
    username: '',
    password: '',
    remote_port: 10000,
  },
  changeIPInfo: {
    ip: '',
    change_remind: 3,
    has_state: [],
    range_ip: [],
    country: '',
    plan_id: 1,
    show_isp: true,
    isp: [],
    support_os: [],
    current_os: 1,
    current_remote_port: '',
  },
  reinstallServerResult: {
    ip: '',
    password: '',
    remote_port: '',
    username: '',
  },
  statusLoadRenewServer: 'idle',
  statusLoadCalculateRenewMultipleServer: 'idle',
  listSupportOS: {
    os: {},
  },
  listServerTable: {
    servers: [],
    total_vps: 0,
    total_vps_off: 0,
    total_vps_running: 0,
  },
};

export const actionGetListServerTable = createAsyncThunk(
  'listServerTable/actionGetListServerTable',
  async (params: IGetListServerTable, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/list',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionRebootServer = createAsyncThunk(
  'listServerTable/actionRebootServer',
  async (params: PayloadServerControl, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/reboot',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionPauseServer = createAsyncThunk(
  'listServerTable/actionPauseServer',
  async (params: PayloadServerControl, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/pause',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionAutoFix = createAsyncThunk(
  'listServerTable/actionAutoFix',
  async (params: PayloadServerControl, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/auto-fix',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionAutoRenew = createAsyncThunk(
  'listServerTable/actionAutoRenew',
  async (params: PayloadAutoRenew, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/auto-renew',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetSupportOS = createAsyncThunk(
  'listServerTable/actionGetSupportOS',
  async (params: PayloadGetSupportOS, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/vps/support/os',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionReinstallServer = createAsyncThunk(
  'listServerTable/actionReinstallServer',
  async (params: PayloadReinstallServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/reinstall',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionCalculateRenewServer = createAsyncThunk(
  'listServerTable/actionCalculateRenewServer',
  async (params: PayloadCalculateRenewServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/renew/calculate',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionCalculateRenewMultipleServer = createAsyncThunk(
  'listServerTable/actionCalculateRenewMultipleServer',
  async (params: PayloadCalculateRenewServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/renew/calculate_multiples',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionRenewServer = createAsyncThunk(
  'listServerTable/actionRenewServer',
  async (params: PayloadCalculateRenewServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/renew',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionRefund = createAsyncThunk(
  'listServerTable/actionRefund',
  async (params: PayloadRefund, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/refund',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionCalculateRefund = createAsyncThunk(
  'listServerTable/actionCalculateRefund',
  async (params: PayloadRefund, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/refund/calculate',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionServerInputNote = createAsyncThunk(
  'listServerTable/actionServerInputNote',
  async (params: PayloadActionServerInputNote, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/info/note',
        method: 'PUT',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionShowServerLoginInfo = createAsyncThunk(
  'listServerTable/actionShowServerLoginInfo',
  async (params: PayloadActionShowServerLoginInfo, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/info',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionResetPassword = createAsyncThunk(
  'listServerTable/actionResetPassword',
  async (data: PayloadActionResetPassword, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/reset-password',
        method: 'POST',
        data,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetChangeIPInfo = createAsyncThunk(
  'listServerTable/actionGetChangeIPInfo',
  async (params: IChangeIPInfo, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/change-ip-params',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionChangeIP = createAsyncThunk(
  'listServerTable/actionChangeIP',
  async (data: IChangeIP, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/change-ip',
        method: 'POST',
        data,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionFindUser = createAsyncThunk(
  'listServerTable/actionFindUser',
  async (data: IFindUser, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/transfer/find_user',
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionTransferServer = createAsyncThunk(
  'listServerTable/actionTransferServer',
  async (data: ITransfer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/transfer',
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'listServerTable',
  initialState,
  reducers: {
    actionResetListServerStore() {
      return initialState;
    },
    actionResetRefundServer(state) {
      state.statusLoadCalculateRefund = 'idle';
      state.statusLoadRefund = 'idle';
    },
    actionResetChangeIP(state) {
      state.statusLoadChangeIP = 'idle';
    },
    actionResetReinstallServer(state) {
      state.statusLoadReinstallServer = 'idle';
    },
    actionResetFindUser(state) {
      state.statusLoadFindUser = 'idle';
    },
    actionResetTransferServer(state) {
      state.statusLoadTransferServer = 'idle';
    },
    actionResetRenewServer(state) {
      state.statusLoadRenewServer = 'idle';
    },
    actionResetGetChangeIPInfo(state) {
      state.statusLoadGetChangeIPInfo = 'idle';
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetListServerTable.pending, (state) => {
      state.statusLoadListServerTable = 'loading';
    });
    builder.addCase(actionGetListServerTable.fulfilled, (state, action) => {
      state.statusLoadListServerTable = 'success';
      state.listServerTable = get(action, 'payload.data');
    });
    builder.addCase(actionGetListServerTable.rejected, (state) => {
      state.statusLoadListServerTable = 'error';
    });

    builder.addCase(actionRebootServer.pending, (state) => {
      state.statusLoadRebootServer = 'loading';
    });
    builder.addCase(actionRebootServer.fulfilled, (state) => {
      state.statusLoadRebootServer = 'success';
      notification.success({ message: 'Khởi động lại thành công' });
    });
    builder.addCase(actionRebootServer.rejected, (state, action) => {
      state.statusLoadRebootServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionPauseServer.pending, (state) => {
      state.statusLoadPauseServer = 'loading';
    });
    builder.addCase(actionPauseServer.fulfilled, (state) => {
      state.statusLoadPauseServer = 'success';
      notification.success({ message: 'Server tạm dừng' });
    });
    builder.addCase(actionPauseServer.rejected, (state, action) => {
      state.statusLoadPauseServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionAutoFix.pending, (state) => {
      state.statusLoadAutoFix = 'loading';
    });
    builder.addCase(actionAutoFix.fulfilled, (state) => {
      state.statusLoadAutoFix = 'success';
      notification.success({ message: 'Sửa lỗi tự động thành công' });
    });
    builder.addCase(actionAutoFix.rejected, (state, action) => {
      state.statusLoadAutoFix = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionGetSupportOS.pending, (state) => {
      state.statusLoadSupportOS = 'loading';
    });
    builder.addCase(actionGetSupportOS.fulfilled, (state, action) => {
      state.statusLoadSupportOS = 'success';
      state.listSupportOS = get(action, 'payload.data');
    });
    builder.addCase(actionGetSupportOS.rejected, (state) => {
      state.statusLoadSupportOS = 'error';
    });

    builder.addCase(actionReinstallServer.pending, (state) => {
      state.statusLoadReinstallServer = 'loading';
    });
    builder.addCase(actionReinstallServer.fulfilled, (state, action) => {
      state.statusLoadReinstallServer = 'success';
      state.reinstallServerResult = get(action, 'payload.data');
    });
    builder.addCase(actionReinstallServer.rejected, (state, action) => {
      state.statusLoadReinstallServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionCalculateRenewServer.pending, (state) => {
      state.statusLoadCalculateRenewServer = 'loading';
    });
    builder.addCase(actionCalculateRenewServer.fulfilled, (state, action) => {
      state.statusLoadCalculateRenewServer = 'success';
      state.itemsRenew = get(action, 'payload.data');
    });
    builder.addCase(actionCalculateRenewServer.rejected, (state, action) => {
      state.statusLoadCalculateRenewServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionCalculateRenewMultipleServer.pending, (state) => {
      state.statusLoadCalculateRenewMultipleServer = 'loading';
    });
    builder.addCase(actionCalculateRenewMultipleServer.fulfilled, (state, action) => {
      state.statusLoadCalculateRenewMultipleServer = 'success';
      state.itemsRenewCalculate = get(action, 'payload.data');
    });
    builder.addCase(actionCalculateRenewMultipleServer.rejected, (state, action) => {
      state.statusLoadCalculateRenewMultipleServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionRenewServer.pending, (state) => {
      state.statusLoadRenewServer = 'loading';
    });
    builder.addCase(actionRenewServer.fulfilled, (state, action) => {
      state.statusLoadRenewServer = 'success';
      state.itemsRenew = get(action, 'payload.data');
    });
    builder.addCase(actionRenewServer.rejected, (state, action) => {
      state.statusLoadRenewServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionCalculateRefund.pending, (state) => {
      state.statusLoadCalculateRefund = 'loading';
    });
    builder.addCase(actionCalculateRefund.fulfilled, (state, action) => {
      state.statusLoadCalculateRefund = 'success';
      state.calculateRefund = get(action, 'payload.data');
    });
    builder.addCase(actionCalculateRefund.rejected, (state, action) => {
      state.statusLoadCalculateRefund = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionRefund.pending, (state) => {
      state.statusLoadRefund = 'loading';
    });
    builder.addCase(actionRefund.fulfilled, (state, action) => {
      state.statusLoadRefund = 'success';
      state.serverRefund = get(action, 'payload.data');
    });
    builder.addCase(actionRefund.rejected, (state, action) => {
      state.statusLoadRefund = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionServerInputNote.pending, (state) => {
      state.statusLoadServerInputNote = 'loading';
    });
    builder.addCase(actionServerInputNote.fulfilled, (state) => {
      state.statusLoadServerInputNote = 'success';
      notification.success({ message: 'Thành công' });
    });
    builder.addCase(actionServerInputNote.rejected, (state) => {
      state.statusLoadServerInputNote = 'error';
      notification.error({ message: 'Sửa ghi chú thất bại. Vui lòng thử lại' });
    });

    builder.addCase(actionAutoRenew.pending, (state) => {
      state.statusLoadAutoRenew = 'loading';
    });
    builder.addCase(actionAutoRenew.fulfilled, (state) => {
      state.statusLoadAutoRenew = 'success';
      notification.success({ message: 'Thành công' });
    });
    builder.addCase(actionAutoRenew.rejected, (state) => {
      state.statusLoadAutoRenew = 'error';
    });

    builder.addCase(actionShowServerLoginInfo.pending, () => {});
    builder.addCase(actionShowServerLoginInfo.fulfilled, (state, action) => {
      state.serverLoginInfo = get(action, 'payload.data');
    });
    builder.addCase(actionShowServerLoginInfo.rejected, () => {});

    builder.addCase(actionResetPassword.pending, (state) => {
      state.statusLoadResetPassword = 'loading';
    });
    builder.addCase(actionResetPassword.fulfilled, (state) => {
      state.statusLoadResetPassword = 'success';
      notification.success({ message: 'Reset password thành công.' });
    });
    builder.addCase(actionResetPassword.rejected, (state) => {
      state.statusLoadResetPassword = 'error';
    });

    builder.addCase(actionChangeIP.pending, (state) => {
      state.statusLoadChangeIP = 'loading';
    });
    builder.addCase(actionChangeIP.fulfilled, (state, action) => {
      state.statusLoadChangeIP = 'success';
      state.changeIPResult = get(action, 'payload.data');
    });
    builder.addCase(actionChangeIP.rejected, (state, action) => {
      state.statusLoadChangeIP = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionGetChangeIPInfo.pending, (state) => {
      state.statusLoadGetChangeIPInfo = 'loading';
    });
    builder.addCase(actionGetChangeIPInfo.fulfilled, (state, action) => {
      state.statusLoadGetChangeIPInfo = 'success';
      state.changeIPInfo = get(action, 'payload.data');
    });
    builder.addCase(actionGetChangeIPInfo.rejected, (state, action) => {
      state.statusLoadGetChangeIPInfo = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionFindUser.pending, (state) => {
      state.statusLoadFindUser = 'loading';
    });
    builder.addCase(actionFindUser.fulfilled, (state, action) => {
      state.statusLoadFindUser = 'success';
      state.findUser = get(action, 'payload.data');
    });
    builder.addCase(actionFindUser.rejected, (state, action) => {
      state.statusLoadFindUser = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionTransferServer.pending, (state) => {
      state.statusLoadTransferServer = 'loading';
    });
    builder.addCase(actionTransferServer.fulfilled, (state, action) => {
      state.statusLoadTransferServer = 'success';
      state.transferServerResult = get(action, 'payload.data');
    });
    builder.addCase(actionTransferServer.rejected, (state, action) => {
      state.statusLoadTransferServer = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });
  },
});

export const {
  actionResetListServerStore,
  actionResetRefundServer,
  actionResetChangeIP,
  actionResetReinstallServer,
  actionResetFindUser,
  actionResetTransferServer,
  actionResetRenewServer,
  actionResetGetChangeIPInfo,
} = slice.actions;

export const selectLoadingListServerTable = (state: RootState) => state.listServerTable.statusLoadListServerTable;
export const selectLoadingRebootServer = (state: RootState) => state.listServerTable.statusLoadRebootServer;
export const selectLoadingPauseServer = (state: RootState) => state.listServerTable.statusLoadPauseServer;
export const selectLoadingAutoFix = (state: RootState) => state.listServerTable.statusLoadAutoFix;
export const selectLoadingSupportOS = (state: RootState) => state.listServerTable.statusLoadSupportOS;
export const selectReinstallServerResult = (state: RootState) => state.listServerTable.reinstallServerResult;
export const selectLoadingReinstallServer = (state: RootState) => state.listServerTable.statusLoadReinstallServer;
export const selectLoadingRenewServer = (state: RootState) => state.listServerTable.statusLoadRenewServer;
export const selectLoadingCalculateRenewMultipleServer = (state: RootState) =>
  state.listServerTable.statusLoadCalculateRenewMultipleServer;
export const selectLoadingCalculateRenewServer = (state: RootState) =>
  state.listServerTable.statusLoadCalculateRenewServer;
export const selectLoadingAutoRenew = (state: RootState) => state.listServerTable.statusLoadAutoRenew;
export const selectLoadingCalculateRefund = (state: RootState) => state.listServerTable.statusLoadCalculateRefund;
export const selectLoadingRefund = (state: RootState) => state.listServerTable.statusLoadRefund;
export const selectLoadingServerInputNote = (state: RootState) => state.listServerTable.statusLoadServerInputNote;
export const selectLoadingResetPassword = (state: RootState) => state.listServerTable.statusLoadResetPassword;
export const selectLoadingChangeIP = (state: RootState) => state.listServerTable.statusLoadChangeIP;
export const selectLoadingGetChangeIPInfo = (state: RootState) => state.listServerTable.statusLoadGetChangeIPInfo;
export const selectLoadingFindUser = (state: RootState) => state.listServerTable.statusLoadFindUser;
export const selectLoadingTransferUser = (state: RootState) => state.listServerTable.statusLoadTransferServer;
export const selectItemsRenew = (state: RootState) => state.listServerTable.itemsRenew;
export const selectCalculateRefund = (state: RootState) => state.listServerTable.calculateRefund;
export const selectRenewCalculate = (state: RootState) => state.listServerTable.itemsRenewCalculate;
export const selectServerRefund = (state: RootState) => state.listServerTable.serverRefund;
export const selectServerLoginInfo = (state: RootState) => state.listServerTable.serverLoginInfo;
export const selectChangeIPResult = (state: RootState) => state.listServerTable.changeIPResult;
export const selectChangeIPInfo = (state: RootState) => state.listServerTable.changeIPInfo;
export const selectListServerTable = (state: RootState) => state.listServerTable.listServerTable;
export const selectListSupportOS = (state: RootState) => state.listServerTable.listSupportOS;
export const selectFindUserResult = (state: RootState) => state.listServerTable.findUser;
export const selectTransferServerResult = (state: RootState) => state.listServerTable.transferServerResult;

export default slice.reducer;
