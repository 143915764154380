import { Button, Col, Descriptions, Modal, Row, Typography } from 'antd';
import { CopyOutlined, CreditCardOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

const { Text } = Typography;

export default function Recharge() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, copy] = useCopyToClipboard();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const copyMoneyTransferContent = () => {
    const content = localStorage.getItem('banking_message') as string;
    copy(content);
  };

  return (
    <>
      <Button
        type="primary"
        size="large"
        icon={<CreditCardOutlined />}
        className="bg-[#f5222d] text-sm"
        onClick={showModal}
      >
        Nạp tiền
      </Button>

      <Modal
        title="Nạp tiền vào tài khoản"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <img
              src={`https://img.vietqr.io/image/970436-${
                localStorage.getItem('bank_acc_no') as string
              }-KvJmf8.jpg?addInfo=${localStorage.getItem('banking_message') as string}`}
              width={300}
              height={300}
              className="mx-auto"
              alt="img"
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Descriptions title="Chuyển khoản ngân hàng" layout="vertical">
              <Descriptions.Item label="Tên ngân hàng">{localStorage.getItem('bank_name') as string}</Descriptions.Item>
              <Descriptions.Item label="Chủ tài khoản">
                {localStorage.getItem('bank_acc_name') as string}
              </Descriptions.Item>
              <Descriptions.Item label="Số tài khoản">
                {localStorage.getItem('bank_acc_no') as string}
                <CopyOutlined
                  className="ml-2 inline-block"
                  style={{ color: '#007bfc' }}
                  onClick={() => {
                    copy(localStorage.getItem('bank_acc_no') as string);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Nội dung chuyển khoản">
                {localStorage.getItem('banking_message')}
                <CopyOutlined
                  className="ml-2 inline-block"
                  style={{ color: '#007bfc' }}
                  onClick={copyMoneyTransferContent}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Lưu ý">
                <Text type="danger">
                  Vui lòng chuyển khoản tối thiếu 50.000 VNĐ (năm mươi nghìn Việt Nam Đồng) để hệ thống có thể xử lý.
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
