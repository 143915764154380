/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface IIPtoSIDs {
  ip: string;
  id: number;
  client: number;
  error: string;
  expired_at: string;
  VND: string;
}

interface IGetSidlistTable {
  result: string;
  reason: string;
  details: IIPtoSIDs[];
}

interface getListBlacklistTable {
  statusLoadPostIPs: Status;
  listSidsTable: IGetSidlistTable;
}

const initialState: getListBlacklistTable = {
  statusLoadPostIPs: 'idle',
  listSidsTable: {
    result: '',
    reason: '',
    details: [],
  },
};

export const actionPostIPs = createAsyncThunk(
  'listServerTable/actionPostIPs',
  async (params: string[], { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/ips_to_sids',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'listSidlistTable',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionPostIPs.pending, (state) => {
      state.statusLoadPostIPs = 'loading';
    });
    builder.addCase(actionPostIPs.fulfilled, (state, action) => {
      state.statusLoadPostIPs = 'success';
      state.listSidsTable = get(action, 'payload.data');
    });
    builder.addCase(actionPostIPs.rejected, (state) => {
      state.statusLoadPostIPs = 'error';
    });
  },
});

export const selectLoadingSidlist = (state: RootState) => state.renewByIp.statusLoadPostIPs;
export const selectSidlist = (state: RootState) => state.renewByIp.listSidsTable;

export default slice.reducer;
