import axios, { AxiosRequestConfig } from 'axios';
import { REACT_APP_API } from 'constant/common';
import URL from 'constant/url';

export const instanceAxios = axios.create({
  baseURL: REACT_APP_API,
});

instanceAxios.defaults.headers.common['Content-Type'] = 'application/json';

instanceAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const { url } = error.config;
    const isUrlLogin = url === '/api/token';
    if (error.response.status === 401 && !isUrlLogin) {
      localStorage.clear();
      window.location.pathname = URL.Unauthorized;
    }
    return Promise.reject(error);
  }
);

export default function request(options: AxiosRequestConfig) {
  return instanceAxios(options);
}
