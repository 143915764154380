import { UserOutlined, DollarOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { selectUserProfile } from 'components/Account/AccountProfileTab/slice';
import { useAppSelector } from 'redux/store';

interface IAccountBalance {
  checkAmountMoney: () => void;
  isLoadingAmountButton: boolean;
}

export default function AccountBalance(props: IAccountBalance) {
  const { checkAmountMoney, isLoadingAmountButton } = props;
  const userProfile = useAppSelector(selectUserProfile);

  return (
    <Tooltip title="Click để kiểm tra số dư hiện tại">
      <Button
        type="primary"
        size="large"
        icon={userProfile.is_staff === true ? <UserOutlined /> : <DollarOutlined />}
        className="bg-[#28a745] text-sm"
        onClick={checkAmountMoney}
        loading={isLoadingAmountButton}
      >
        {userProfile.amount} VNĐ
      </Button>
    </Tooltip>
  );
}
