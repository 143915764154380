const URL = {
  Buying: '/buying',
  BuyingVPSGPU: '/buying-vps-gpu',
  BuyingProxy: '/buy-proxy',
  Account: '/account',
  Management: '/management',
  User: '/users',
  Reseller: '/resell',
  Server: '/servers',
  CheckProxy: '/checkproxy',
  CheckIP: '/checkip',
  Support: '/support',
  History: '/history',
  Blacklist: '/blacklist',
  RenewByIP: '/renew-by-ip',
  Login: '/',
  Register: '/register',
  ForgotPassword: '/forgot',
  VerifyUser: '/verify',
  NotFound: '*',
  Transfer: '/transfer',
  Unauthorized: '/unauthorized',
  Notification: '/notification',
};

export default URL;
