/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { get } from 'lodash';

import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface IListPlan {
  bandwidth: string;
  country: string;
  country_short: string;
  cpu: string;
  ethernet_port: string;
  id: number;
  ipv4: string;
  name: string;
  price: number;
  ram: string;
  ssd: string;
}

interface ICalculatePlan {
  from_plan: string;
  to_plan: string;
  discount: string;
  expense: string;
  expense_details: string;
  days_left: number;
}

interface ITransaction {
  statusLoadListPlan: Status;
  statusCalculatePlan: Status;
  statusUpgradeServer: Status;
  listPlan: IListPlan[];
  listCalculatePlan: ICalculatePlan;
}

interface PayloadListPlan {
  sid: string;
}

interface PayloadCalculatePlanUpgrade {
  sid: string;
  plan_id: string;
}

const initialState: ITransaction = {
  statusLoadListPlan: 'idle',
  statusCalculatePlan: 'idle',
  statusUpgradeServer: 'idle',
  listPlan: [],
  listCalculatePlan: {
    from_plan: '',
    to_plan: '',
    discount: '',
    expense: '',
    expense_details: '',
    days_left: 0,
  },
};

export const actionGetListPlan = createAsyncThunk(
  'upgrade/actionGetListPlan',
  async (params: PayloadListPlan, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/upgrade/plans',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionCalculatePlanUpgrade = createAsyncThunk(
  'upgrade/actionCalculatePlanUpgrade',
  async (params: PayloadCalculatePlanUpgrade, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/upgrade/calculate',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionUpgradeServer = createAsyncThunk(
  'upgrade/actionUpgradeServer',
  async (params: PayloadCalculatePlanUpgrade, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/upgrade',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'upgrade',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetListPlan.pending, (state) => {
      state.statusLoadListPlan = 'loading';
    });
    builder.addCase(actionGetListPlan.fulfilled, (state, action) => {
      state.statusLoadListPlan = 'success';
      state.listPlan = get(action, 'payload.data');
    });
    builder.addCase(actionGetListPlan.rejected, (state, action) => {
      state.statusLoadListPlan = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionCalculatePlanUpgrade.pending, (state) => {
      state.statusCalculatePlan = 'loading';
    });
    builder.addCase(actionCalculatePlanUpgrade.fulfilled, (state, action) => {
      state.statusCalculatePlan = 'success';
      state.listCalculatePlan = get(action, 'payload.data');
    });
    builder.addCase(actionCalculatePlanUpgrade.rejected, (state, action) => {
      state.statusCalculatePlan = 'error';
      notification.error({ message: get(action, 'payload.response.data.reason') });
    });

    builder.addCase(actionUpgradeServer.pending, (state) => {
      state.statusUpgradeServer = 'loading';
    });
    builder.addCase(actionUpgradeServer.fulfilled, (state) => {
      state.statusUpgradeServer = 'success';
      notification.success({ message: 'Nâng cấp thành công' });
    });
    builder.addCase(actionUpgradeServer.rejected, (state) => {
      state.statusUpgradeServer = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingListPlan = (state: RootState) => state.upgrade.statusLoadListPlan;
export const selectListPlan = (state: RootState) => state.upgrade.listPlan;
export const selectCalculatePlan = (state: RootState) => state.upgrade.listCalculatePlan;
export const selectLoadingCalculatePlan = (state: RootState) => state.upgrade.statusCalculatePlan;
export const selectLoadingUpgradeServer = (state: RootState) => state.upgrade.statusUpgradeServer;

export default slice.reducer;
