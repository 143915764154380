import { MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import Sidebar from 'layouts/Sidebar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function MobileMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function handleSelectItem(data: { key: string }) {
    navigate(data.key);
    setOpen(false);
  }

  return (
    <>
      <MenuOutlined className="ml-4 mobile:ml-1 cursor-pointer lg:hidden" onClick={() => setOpen(true)} />
      <Drawer
        className="ant-menu-mobile hidden mobile:block lg:hidden shadow-none"
        title=""
        placement="left"
        closeIcon={null}
        width={240}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Sidebar handleSelectItem={handleSelectItem} />
      </Drawer>
    </>
  );
}
