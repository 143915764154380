/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface ISupportOption {
  software: {
    type: string;
    multichoice: string;
    option: {
      chrome: string;
      firefox: string;
    };
  };
  os: {
    type: string;
    multichoice: string;
    option: Record<string, number | boolean | string>;
  };
  port: {
    type: string;
    multichoice: string;
    option: {
      port: string;
    };
  };
  username: {
    type: string;
    multichoice: string;
    option: {
      random: string;
    };
  };
  password: {
    type: string;
    multichoice: string;
    option: {
      random: string;
    };
  };
  random_password: {
    type: string;
    multichoice: string;
    option: {
      password: string;
    };
  };
  plan_id: {
    type: string;
    multichoice: string;
    option: Array<string>;
  };
  range_ip: {
    type: string;
    multichoice: string;
    option: Array<string>;
  };
  duration: {
    type: string;
    multichoice: string;
    option: Record<string, number | boolean | string>;
  };
}

export interface PayloadActionListGPU {
  id: number;
  slug: string;
  name: string;
  description: string;
  price: number;
  cpu: string;
  ram: string;
  ssd: string;
  bandwidth: string;
  ethernet_port: string;
  ipv4: string;
  status: string;
  region: string;
  featured: boolean;
  vRAM: string;
}

interface getListGPU {
  statusLoadingListGPU: Status;
  statusLoadListSupportOption: Status;
  listSupportOption: ISupportOption;
  listGPU: PayloadActionListGPU[];
}

const initialState: getListGPU = {
  statusLoadingListGPU: 'idle',
  statusLoadListSupportOption: 'idle',
  listGPU: [],
  listSupportOption: {
    software: {
      type: 'checkbox',
      multichoice: 'true',
      option: {
        chrome: 'chrome',
        firefox: 'firefox',
      },
    },
    os: {
      type: 'dropdown',
      multichoice: 'false',
      option: {},
    },
    port: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        port: 'Random port',
      },
    },
    username: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        random: 'Random password',
      },
    },
    password: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        random: 'Random password',
      },
    },
    random_password: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        password: 't4k*i8mQC#',
      },
    },
    plan_id: {
      type: 'dropdown',
      multichoice: 'false',
      option: [],
    },
    range_ip: {
      type: 'dropdown',
      multichoice: 'false',
      option: [],
    },
    duration: {
      type: 'dropdown',
      multichoice: 'false',
      option: {},
    },
  },
};

export const actionGetListGPU = createAsyncThunk('buyVPSGPU/actionGetListGPU', async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: 'api/plan/gpu',
      method: 'GET',
      params,
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const actionGetListGPUSupportOption = createAsyncThunk(
  'buyGPU/actionGetListGPUSupportOption',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'api/server/gpu/support',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'buyVPSGPU',
  initialState,
  reducers: {
    actionResetListGPU() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetListGPU.pending, (state) => {
      state.statusLoadingListGPU = 'loading';
    });
    builder.addCase(actionGetListGPU.fulfilled, (state, action) => {
      state.statusLoadingListGPU = 'success';
      state.listGPU = get(action, 'payload.data');
    });
    builder.addCase(actionGetListGPU.rejected, (state) => {
      state.statusLoadingListGPU = 'error';
    });

    builder.addCase(actionGetListGPUSupportOption.pending, (state) => {
      state.statusLoadListSupportOption = 'loading';
    });
    builder.addCase(actionGetListGPUSupportOption.fulfilled, (state, action) => {
      state.statusLoadListSupportOption = 'success';
      state.listSupportOption = get(action, 'payload.data');
    });
    builder.addCase(actionGetListGPUSupportOption.rejected, (state) => {
      state.statusLoadListSupportOption = 'error';
    });
  },
});

export const { actionResetListGPU } = slice.actions;

export const selectListGPU = (state: RootState) => state.buyVPSGPU.listGPU;
export const selectLoadingListGPU = (state: RootState) => state.buyVPSGPU.statusLoadingListGPU;
export const selectGPUOptions = (state: RootState) => state.buyVPSGPU.listSupportOption;
export const selectLoadingGPUOption = (state: RootState) => state.buyVPSGPU.statusLoadListSupportOption;

export default slice.reducer;
