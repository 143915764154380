/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface IProfile {
  address: string;
  amount: number;
  site_balance: number;
  discount_dedicate: number;
  discount_proxy: number;
  discount_vps: number;
  is_notification: boolean;
  is_reseller: boolean;
  is_staff: boolean;
  is_refund: boolean;
  is_reset_pass: boolean;
  is_verified: boolean;
  phone: string;
}

interface UpdateProfile {
  address: string;
}

interface getUserProfile {
  statusLoadUserProfile: Status;
  statusLoadUpdateProfile: Status;
  profile: IProfile;
  updateProfile: UpdateProfile;
}

const initialState: getUserProfile = {
  statusLoadUserProfile: 'idle',
  statusLoadUpdateProfile: 'idle',
  profile: {
    address: '',
    amount: 0,
    site_balance: 0,
    discount_dedicate: 0,
    discount_proxy: 0,
    discount_vps: 0,
    is_notification: false,
    is_reseller: false,
    is_verified: false,
    is_staff: false,
    is_refund: false,
    is_reset_pass: false,
    phone: '',
  },
  updateProfile: {
    address: '',
  },
};

export const actionGetUserProfile = createAsyncThunk(
  'userProfile/actionGetUserProfile',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/user/profile',
        method: 'GET',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionUpdateProfile = createAsyncThunk(
  'userProfile/actionUpdateProfile',
  async (params: UpdateProfile, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/user/profile',
        method: 'PUT',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetUserProfile.pending, (state) => {
      state.statusLoadUserProfile = 'loading';
    });
    builder.addCase(actionGetUserProfile.fulfilled, (state, action) => {
      state.statusLoadUserProfile = 'success';
      state.profile = get(action, 'payload.data');
      if (state.profile.is_verified === true) localStorage.setItem('is_verified', 'true');
    });
    builder.addCase(actionGetUserProfile.rejected, (state) => {
      state.statusLoadUserProfile = 'error';
    });

    builder.addCase(actionUpdateProfile.pending, (state) => {
      state.statusLoadUpdateProfile = 'loading';
    });
    builder.addCase(actionUpdateProfile.fulfilled, (state) => {
      state.statusLoadUpdateProfile = 'success';
    });
    builder.addCase(actionUpdateProfile.rejected, (state) => {
      state.statusLoadUpdateProfile = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingUserProfile = (state: RootState) => state.userProfile.statusLoadUserProfile;
export const selectStatusLoadUpdateProfile = (state: RootState) => state.userProfile.statusLoadUpdateProfile;
export const selectUserProfile = (state: RootState) => state.userProfile.profile;

export default slice.reducer;
