/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

interface ISendVerifyToken {
  token: string | null;
}

interface getUserProfile {
  statusLoadVerifyUser: Status;
  statusLoadVerifyToken: Status;
}

const initialState: getUserProfile = {
  statusLoadVerifyUser: 'idle',
  statusLoadVerifyToken: 'idle',
};

export const actionVerifyUser = createAsyncThunk('verifyUser/actionVerifyUser', async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/api/user/verify',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const actionSendVerifyToken = createAsyncThunk(
  'verifyUser/actionSendVerifyToken',
  async (data: ISendVerifyToken, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/user/verify',
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'verifyUser',
  initialState,
  reducers: {
    actionResetStore() {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(actionVerifyUser.pending, (state) => {
      state.statusLoadVerifyUser = 'loading';
    });
    builder.addCase(actionVerifyUser.fulfilled, (state) => {
      state.statusLoadVerifyUser = 'success';
    });
    builder.addCase(actionVerifyUser.rejected, (state) => {
      state.statusLoadVerifyUser = 'error';
    });
    builder.addCase(actionSendVerifyToken.pending, (state) => {
      state.statusLoadVerifyToken = 'loading';
    });
    builder.addCase(actionSendVerifyToken.fulfilled, (state) => {
      state.statusLoadVerifyToken = 'success';
    });
    builder.addCase(actionSendVerifyToken.rejected, (state) => {
      state.statusLoadVerifyToken = 'error';
    });
  },
});

export const { actionResetStore } = slice.actions;

export const selectLoadingVerifyUser = (state: RootState) => state.verifyUser.statusLoadVerifyUser;
export const selectLoadingVerifyToken = (state: RootState) => state.verifyUser.statusLoadVerifyToken;

export default slice.reducer;
